.kanjiBox {
  width: 100%; }

.scriptBox {
  width: 100%; }

.searchButton {
  width: 100%; }

@media (min-width: 576px) {
  .kanjiBox {
    width: 12rem; }
  .scriptBox {
    width: 10rem; }
  .searchButton {
    width: 7rem; } }

.resultImage {
  opacity: 1;
  transition: opacity .25s;
  padding: 10px; }

.resultImage.fadeIn {
  opacity: 1; }

.resultsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start; }

.resultDiv {
  opacity: 0;
  transition: opacity .3s;
  width: 140px;
  margin: 10px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  box-shadow: 1px 0 1px #f3f3f3; }

.resultDiv.fadeIn {
  opacity: 1; }

.root {
  background-color: #f0f0f0; }

.resultText {
  background-color: #f8f8f8;
  color: #666;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12pt;
  text-align: center;
  padding: 5px 10px 5px 10px; }

.scrollable-menu {
  height: auto;
  max-height: 200px;
  overflow-x: hidden; }

/*# sourceMappingURL=style.css.map */
